const AboutMeContent = () => {

    return (
        <div>
            <h1 style={{marginBottom: '20px'}}>Hello world! 👋</h1> 
            <p>
                I am Rohan Nankani, a first-year Software Engineering student @ UofWaterloo <br/>
                <br/>
                I have a passion for full-stack engineering and interest in AI/ML/Deep Learning. <br/>
                <br/>
                My hobbies include 💻 ⚽ 🧗‍♂️ ♟️ <br/>
            </p>
        </div>
    );
}

export default AboutMeContent;