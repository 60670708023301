import './App.css';
import Home from './components/Home'

function App() {
  
  return ( 
    <div className='App'>
      <Home />
      <div className="bottom-space"></div>
    </div>
  );
}


export default App;
